import { Format, QualityVariantType } from '@/types/products';

export const CUSTOMIZABLE_CARD_ID = 'cardsCustom';

export const UNIVERSE_ID = 1;

export const PREFERRED_PAPER_QUALITY_ORDER = [
  QualityVariantType.DoubleSidedStandard,
  QualityVariantType.FoldedStandard,
  QualityVariantType.DoubleSidedPremium,
  QualityVariantType.FoldedPremium,
];
export const PREFERRED_FORMAT_ORDER = [Format.DoubleSided, Format.Folded];
