import { Ratio } from '@/types/ratio';

export interface ProductBorders {
  outer: {
    maxEnd: number;
    maxStart: number;
    minEnd: number;
    minStart: number;
  };
}

export enum QualityVariantType {
  DoubleSidedPremium = 'doubleSidedPremium',
  DoubleSidedStandard = 'doubleSidedStandard',
  FoldedPremium = 'foldedPremium',
  FoldedStandard = 'foldedStandard',
  OneSized = 'oneSided',
}

export enum Quality {
  Premium = 'premium',
  Standard = 'standard',
}

export enum Format {
  DoubleSided = 'doubleSided',
  Folded = 'folded',
}

export type VariantInfo = {
  id: string;
  type: string;
  bundle?: number;
};

export type Dpi = {
  minDpi: number;
  maxDpi: number;
};

export type ProductSpecs = {
  size: Ratio;
  borders?: ProductBorders;
  dpi?: Dpi;
  bleedSize?: number;
  repeatHorizontal?: number;
  repeatVertical?: number;
  supportedOrientations?: string[];
  augmentedReality?: {
    enabled: boolean;
    model?: {
      url: string;
    };
  };
  editMode?: 'SIMPLE' | 'ADVANCED';
  pageCount?: number;
  variantInfo?: VariantInfo;
};

export type PriceTier = {
  from: number;
  to?: number;
  unitPrice: number;
};

export type PriceTiers = PriceTier[];

export interface Product {
  id: string;
  labels: {
    name: string;
    shortName: string;
    size?: string;
  };
  price: {
    currency: string;
    tiers: PriceTiers;
  };
  relatedSquareProductId?: string;
  specs: ProductSpecs;
}

export interface CardsRemoteConfig {
  title: string;
  collectionIds: number[];
  tileLabel: string;
  moreLabel: string;
  productId: string;
  index: number;
}

export interface ProductGroup {
  default: boolean;
  id: string;
  projectionUrl: string;
  collectionId?: string;
  labels?: {
    tag?: string;
    name?: string;
  };
}

export interface Group {
  default?: boolean;
  advanced?: boolean;
  id: string;
  labels: {
    name: string;
    shortName?: string;
    tag?: string;
  };
  collectionId?: string;
  products: ProductGroup[];
  projectionUrl: string;
  blurDataURL?: string;
}
